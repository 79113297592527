/*-------------------Header---------------------*/
.heading_img {
    height: 120px;
    cursor: pointer;
    margin-top: 4%;
}

.heading {
    /* font-weight: 800; */
    color: #474747;
    padding-top: 2%;
    font-size: medium;
    font-weight: bold !important;
    font-family: 'Montserrat', sans-serif !important;
}

.subtext {
    color: #474747;
    font-family: 'Avenir', sans-serif !important;
    padding: 2px !important;
    margin-bottom: 10px !important;
}

@media screen and (min-width: 320px) {
    .heading {
        font-size: small;
    }
}

/*-------------------Analyse Button/Texfield--------------*/
.testbuttton {
    background-color: #ff7f16 !important;
    color: white !important;
    padding: 14px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    cursor: pointer !important;
    block-size: 3.5rem;
    border-top-left-radius: 0em !important;
    border-bottom-left-radius: 0em !important;
    box-shadow: none !important;
}

@media screen and (max-width: 597px) {


    .testbuttton {
        margin-top: 2% !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        /* text-align: center !important; */
    }

}

.example {
    color: #474747;
    font-family: 'Avenir', sans-serif !important;
    /* text-align: center; */
    padding-left: 2em !important;
}

.example span {
    cursor: pointer;
    color: #ff7f16;
    font-family: 'Avenir', sans-serif;
}



/*----------------Text Bottom--------------------*/
.logo {
    cursor: pointer;
    margin: 4%;
}

.subHeading {
    text-align: left;
    padding-left: 2%;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold !important;
    padding-bottom: 4% !important;
}

.subtitle1 {
    text-align: left;
    font-weight: 300;
    padding-left: 2%;
    font-family: 'Avenir', sans-serif !important;
}

.body1 {
    text-align: left;
    font-weight: 300;
    padding-left: 2%;
    padding-top: 2%;
    padding-bottom: 4%;

    color: #ff7f16;
    font-family: 'Avenir', sans-serif !important;
    cursor: pointer;
    text-decoration: underline;
}

/*--------------------------Result---------------------*/
.input_title {
    font-weight: bolder !important;
    font-family: 'Montserrat', sans-serif !important;
    color: #474747;
    padding: 4%;
}

/*----------------Score Canvas -------------*/
.scoreCanvas {
    display: inline-block;
    padding: 10px;
    height: 150px;
    width: 150px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 8px 5px 20px rgb(0 0 0 / 50%);
    font-weight: bold;
    font-size: 90px;
}

.score_desc {
    color: #8a8787;
    font-family: 'Avenir', sans-serif !important;
    padding-top: 4%;
    text-align: left;
}

.value {
    display: inline-block;
    padding: 10px;
    height: 130px;
    width: 130px;
    border-radius: 50%;
    box-shadow: 8px 5px 20px rgb(0 0 0 / 50%);
    font-weight: bold;
    font-size: 50px;
}

.view_images {
    margin-right: 1em !important;
}

.image_1 {
    margin-left: 2em !important;
    position: relative;
    font-size: 13px;
}

.image_2 {
    position: relative;
    font-size: 13px;
}

.view_title_desktop {
    color: grey;
    position: absolute;
    top: 51%;
    left: 58%;
    transform: translate(-50%, -50%);
}

.view_title_mobile {
    color: grey;
    position: absolute;
    top: 50%;
    left: 49%;
    transform: translate(-50%, -50%);
}

.unit {
    font-family: 'Avenir', sans-serif !important;
    color: #474747;
    font-weight: bold !important;
    padding: 1rem;
}

.emoji {
    padding: 1em;
}

.title {
    font-weight: bold !important;
    color: #474747;
    font-family: 'Montserrat', sans-serif !important;
    text-align: left;
    padding: 1rem;
    margin-left: 3rem !important;
}

.title_desc {
    font-family: 'Avenir', sans-serif !important;
    color: #474747;
    text-align: left;
    padding: 1rem;
}

@media screen and (max-width: 540px) {
    .title {
        margin-left: 1rem !important;
    }

    .title_desc {
        margin-left: 2.5rem !important;
    }
}