.main {
    background-color: #004466 !important;
}

.container {
    text-align: center;
    color: white;
    padding: 4%;
}

.heading {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold !important;
    padding-bottom: 2% !important;
}

.text {
    font-family: 'Avenir', sans-serif !important;
}