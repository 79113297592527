img {
    cursor: pointer;
}

.sub_title {
    font-family: 'Avenir', sans-serif !important;
}

.icons {
    margin-top: 8%;
}

.icons_tag {
    color: white;
    padding-left: 8%;
}

.menu {
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
}

.request_demo {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
}

.demo_subtitle {
    font-family: 'Avenir', sans-serif;
}

.link {
    color: white;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Avenir', sans-serif;
}

.digital {
    padding-bottom: 6%;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
}

.footer_text {
    text-align: center;
    font-family: 'Avenir', sans-serif;
}